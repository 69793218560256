<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between mb-1">
                <div>
                    <router-link to="/vdi/pools/new"><b-button variant="orange" class="mr-1" size="sm"><font-awesome-icon icon="plus"/> Pool erstellen</b-button></router-link>
                    <b-button variant="danger" size="sm" class="mb-0" :disabled="selectedItems.length <= 0" v-b-modal.delete-modal>{{ $t('buttons.delete') }}</b-button>
                </div>
                <router-link to="/vdi/pools/settings">
                    <b-button variant="primary" class="mr-1" size="sm">
                        <font-awesome-icon icon="cogs"/>
                        {{ $t('buttons.settings') }}
                    </b-button>
                </router-link>
            </b-col>
            <b-col sm="4" md="5" xl="4" class="mb-1">
                <b-input-group size="sm">
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        :placeholder="$t('generally.tables.tipToSearch')"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    @filtered="onFiltered"
                    stacked="md"
                    :empty-text="$t('generally.tables.emptyText')"
                    :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                    show-empty
                    striped
                    selectable
                    select-mode="multi"
                    ref="poolTable"
                    class="bg-white"
                    @row-selected="onSelected"
                    sort-by="name"
                >
                    <template #head(selected)="{}">
                        <template v-if="selectAll">
                            <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'square']"/></span>
                        </template>
                    </template>
                    <template #head(name)="">{{ $t("generally.tables.name") }}</template>
                    <template #head(publicName)="">{{ $t("pages.vdi.pools.table.poolName") }}</template>
                    <template #head(type)="">{{ $t("generally.tables.type") }}</template>
                    <template #head(disabled)="">{{ $t("generally.tables.disabled") }}</template>
                    <template #head(public)="">{{ $t("generally.tables.public") }}</template>
                    <template #head(max)="">{{ $t("generally.tables.count") }}</template>
                    <template #head(domain)="">{{ $t("generally.tables.domain") }}</template>
                    <template #head(owner)="">{{ $t("generally.tables.owner") }}</template>
                    <template #head(actions)="">{{ $t("generally.tables.actions") }}</template>

                    <template #cell(name)="row">
                        <router-link :to="{ name: 'VdiPoolsDetail', params: { id: row.item.id } }">{{ row.item.name }}</router-link>
                    </template>

                    <template #cell(publicName)="row">
                        <router-link :to="{ name: 'VdiPoolsDetail', params: { id: row.item.id } }">{{ row.item.publicName[locale] }}</router-link>
                    </template>

                    <template #cell(disabled)="row">
                        <font-awesome-icon :class="row.item.disabled ? 'text-danger' : 'text-success'" :icon="row.item.disabled ? 'times' : 'check'"/>
                    </template>

                    <template #cell(public)="row">
                        <font-awesome-icon :class="[row.item.public ? 'text-success' : 'text-danger']" :icon="row.item.public ? 'check' : 'times'"/>
                    </template>

                    <template #cell(domain)="row">
                        <font-awesome-icon :class="row.item.domain ? 'text-success' : 'text-danger'" :icon="row.item.domain ? 'check' : 'times'"/>
                    </template>

                    <template #cell(owner)="row">
                        <span v-if="row.item.owner">{{ row.item.owner.firstName }} {{ row.item.owner.lastName }}</span>
                    </template>

                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true"><font-awesome-icon :icon="['far', 'square']"/></span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>

                    <template #cell(actions)="row">
                        <b-button variant="primary" class="btn-xs mb-0 ml-1" :to="{ name: 'VdiPoolsDetail', params: { id: row.item.id }}">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                        <b-button variant="orange" class="btn-xs mb-0 ml-1" :to="{ name: 'VdiPoolsEdit', params: { id: row.item.id }}">
                            <font-awesome-icon icon="pencil-alt"/>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-modal
            id="delete-modal"
            ref="delete-modal"
            :title="$t('pages.vdi.pools.headers.deletePool')"
            :ok-title="$t('buttons.delete')"
            :cancel-title="$t('buttons.cancel')"
            ok-variant="danger"
            @ok="onDelete"
        >
            {{ $t('pages.vdi.pools.messages.delete.' + (selectedItems.length > 1 ? 'multi' : 'single'), {count: selectedItems.length}) }}
        </b-modal>
    </div>
</template>

<script>
import poolService from "../../../services/poolService";
import {EventBus} from "../../../services/EventBus";
import mainServices from "../../../services/mainServices";

export default {
    name: "PoolList",
    data: () => ({
        fields: [
            {key: 'selected', label: '', sortable: false},
            {key: 'name', label: 'name', sortable: true, sortDirection: 'asc'},
            {key: 'publicName', label: 'publicName', sortable: true, sortDirection: 'asc'},
            {key: 'type', label: 'type', sortable: true, sortDirection: 'asc'},
            {key: 'disabled', label: 'disabled', sortable: true, sortDirection: 'asc'},
            {key: 'public', label: 'public', sortable: true, sortDirection: 'asc'},
            {key: 'max', label: 'count', sortable: true},
            {key: 'domain', label: 'domain', sortable: true},
            {key: 'owner', label: 'owner', sortable: true},
            {key: 'actions', label: 'actions'}
        ],
        publicName: {"de":"","en":""},
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        selectAll: false,
        selectedItems: [],
        items: [],
        eventBus: null,
        locale: 'de',

    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.load();
        // adding EventBus listener
        this.eventBus = EventBus.$on('sync', () => {
            this.load();
        });
        this.locale = mainServices.getLocale();

    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    },
    methods: {
        getLocale() {
            return localStorage.getItem("locale");
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        load() {
            poolService.getAll().then(response => {
                this.items = response.data.map(item => {
                    item.publicName = JSON.parse(item.publicName);
                    return item;
                });
            }).catch();
        },
        checkAll(){
            this.selectAll = !this.selectAll;
            if(this.selectAll){
                this.$refs.poolTable.selectAllRows()
                this.selectedItems = this.items;
            } else {
                this.$refs.poolTable.clearSelected()
                this.selectedItems = [];
            }
        },
        onSelected(items){
            this.selectAll = this.items.length === items.length;
            this.selectedItems = items;
        },
        onDelete() {
            poolService.delete({ids: this.selectedItems.map(item => (item.id))}).then(() => {
                this.$toastr.s(this.$t('pages.vdi.pools.messages.success.deleted'));
                this.load();
            }).catch(error => {
                this.errorHandler(error);
            });
        }
    }

};
</script>

<style scoped>

</style>
